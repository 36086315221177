import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useCallback, useEffect, useState } from "react";
import { CiCamera } from "react-icons/ci";

const EditProfileImage = ({ profileData, updateProfileData, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(profileData?.profileImage || "");

  useEffect(() => {
    if (profileData) {
      setPreviewUrl(profileData?.profileImage || "");
    }
  }, [profileData]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);

      // Create preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = useCallback(async () => {
    const formDataObj = new FormData();

    Object.keys(profileData).forEach((key) => {
      if (key === "profileImage" && profileImage instanceof File) {
        formDataObj.append(key, profileImage, profileImage.name); // Append file
      } else {
        formDataObj.append(key, profileData[key]); // Append other fields
      }
    });

    await updateProfileData(formDataObj);
    setIsOpen(false);
  }, [profileData, profileImage, updateProfileData]);

  return (
    <>
      <Box
        onClick={() => setIsOpen(true)}
        sx={{
          padding: "2px",
          cursor: "pointer",
          display: "flex",
          backgroundColor: "#FFB800",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "4px",
        }}
      >
        <CiCamera size={18} className="z-10" />
      </Box>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "#18181B",
            backgroundImage: "none",
          },
        }}
      >
        {/* <DialogTitle>
          <Typography
            variant="h5"
            sx={{ color: "#FFB800", fontWeight: "bold" }}
          >
            Edit profile image
          </Typography>
        </DialogTitle> */}
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Box sx={{ textAlign: "center", mb: 2 }}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="profile-image-upload"
                  type="file"
                  onChange={handleImageChange}
                />
                <label htmlFor="profile-image-upload">
                  <Box sx={{ position: "relative", display: "inline-block" }}>
                    <Box
                      sx={{
                        width: 120,
                        height: 120,
                        borderRadius: "50%",
                        border: "2px dashed #FFB800",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        overflow: "hidden",
                        backgroundColor: "rgba(255, 184, 0, 0.1)",
                        "&:hover": {
                          backgroundColor: "rgba(255, 184, 0, 0.2)",
                        },
                      }}
                    >
                      {previewUrl ? (
                        <img
                          src={previewUrl}
                          alt="Profile preview"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <CloudUploadIcon
                          sx={{ color: "#FFB800", fontSize: 40 }}
                        />
                      )}
                    </Box>
                  </Box>
                </label>
                <Typography sx={{ color: "#C4C4C4", mt: 1 }}>
                  Click to upload profile image
                </Typography>
              </Box>
            </Grid>
            <Box
              sx={{
                display: "flex",
                gap: 4,
                justifyContent: "center",
                mt: 4,
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => setIsOpen(false)}
                sx={{
                  borderColor: "#FFB800",
                  color: "#C4C4C4",
                  "&:hover": {
                    borderColor: "#FFB800",
                    backgroundColor: "rgba(255, 184, 0, 0.1)",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={isLoading}
                sx={{
                  backgroundColor: "#FFB800",
                  color: "#000",
                  "&:hover": {
                    backgroundColor: "#FFB800",
                    opacity: 0.9,
                  },
                  "&:disabled": {
                    backgroundColor: "#FFB800",
                    opacity: 0.5,
                  },
                }}
              >
                {isLoading ? "Updating..." : "Save"}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditProfileImage;
