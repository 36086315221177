import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FaXTwitter, FaRegShareFromSquare } from "react-icons/fa6";
import { FaDiscord, FaTelegramPlane } from "react-icons/fa";

import useAccountInfo from "../../hooks/useAccountInfo";
import { InactiveFont } from "../ui/Texts";
import EditProfileImage from "./EditProfileImage";

const expoectedSocials = [
  {
    logo: (props) => <FaXTwitter {...props} />,
    name: "Twitter",
    id: "xUsername",
    value: "",
  },
  {
    logo: (props) => <FaDiscord {...props} />,
    name: "Discord",
    id: "discord",
    value: "",
  },
  {
    logo: (props) => <FaTelegramPlane {...props} />,
    name: "Telegram",
    id: "telegram",
    value: "",
  },
  {
    logo: (props) => <FaRegShareFromSquare {...props} />,
    name: "Share",
    id: "share",
    value: "",
  },
];

const ProfileHeader = ({
  profileData,
  updateProfileData,
  isLoading,
  editable,
}) => {
  const { formattedAddress } = useAccountInfo();

  const { palette, ...theme } = useTheme();
  const isMobie = useMediaQuery(theme.breakpoints.down("sm"));

  const profileImage =
    profileData?.profileImage ??
    "https://ishrostorage.blob.core.windows.net/container-3d/MAYC%20banner.png";

  return (
    <Box
      sx={{
        backgroundColor: "#000",
        borderRadius: "20px",
        padding: "32px 16px",
        maxWidth: "431px",
        width: "100%",
        gap: "24px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <img
            width={isMobie ? "124px" : "160px"}
            height={isMobie ? "124px" : "160px"}
            style={{
              objectFit: "cover",
              borderRadius: "50%",
            }}
            src={profileImage}
            alt="profile"
          />
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {editable && (
              <EditProfileImage
                profileData={profileData}
                updateProfileData={updateProfileData}
                isLoading={isLoading}
              />
            )}
          </Box>
        </Box>
        <Typography
          sx={{
            fontWeight: "500",
            fontFamily: "var(--font-i_r)",
            fontSize: { xs: "24px", sm: "32px" },
            textAlign: "center",
            color: "#FFF",
          }}
        >
          {profileData?.firstName || profileData?.lastName
            ? `${profileData?.firstName ?? ""} ${profileData?.lastName ?? ""}`
            : formattedAddress}
        </Typography>
        {/* <InactiveFont
          text="Gs on Ape Holder"
          style={{ fontSize: { xs: "18px", sm: "20px" } }}
        /> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "12px",
            backgroundColor: "#0D0D0D",
            padding: "6px 10px 6px 6px",
          }}
        >
          <img
            src={profileImage}
            alt="profile"
            height={30}
            width={30}
            style={{
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
          <InactiveFont
            text={formattedAddress}
            style={{ fontSize: { xs: "18px", sm: "20px" } }}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "16px", justifyContent: "center" }}>
        {expoectedSocials.map((social) => (
          <Box
            key={social.id}
            sx={{
              padding: "10px",
              backgroundColor: "#222",
              borderRadius: "100%",
              width: "38px",
              height: "38px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {social.logo({
              size: 22,
              color: "#FFB800",
            })}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ProfileHeader;
