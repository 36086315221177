import { useCallback, useEffect, useState } from "react";
import { fetchProfile, updateProfile } from "../services/clientService";

const useProfileData = (address) => {
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchProfileData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await fetchProfile(address ?? null);
      setProfileData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    return;
  }, [address]);

  useEffect(() => {
    fetchProfileData();
  }, [fetchProfileData]);

  const updateProfileData = useCallback(
    async (data) => {
      try {
        setIsLoading(true);
        await updateProfile(data);
      } catch (error) {
        console.log(error);
      } finally {
        fetchProfileData();
        setIsLoading(false);
      }
      return;
    },
    [fetchProfileData]
  );

  return { profileData, isLoading, fetchProfileData, updateProfileData };
};

export default useProfileData;
