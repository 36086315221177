import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import EditProfile from "./EditProfile";
import MyActivities from "./MyActivities";
import TransactionHistory from "./TransactionHistory";

const ProfileBody = ({
  profileData,
  updateProfileData,
  isLoading,
  editable,
}) => {
  const [activeTab, setActiveTab] = useState("edit_profile"); // "edit_profile" | "activity" | "history" | "library"

  return (
    <Box
      sx={{
        backgroundColor: "#000",
        borderRadius: "22px",
        backdropFilter: "blur(20px)",
        width: "100%",
        minHeight: "440px",
        padding: "32px 16px",
        overflowX: "auto",
      }}
    >
      <Tabs
        value={activeTab}
        onChange={(_, value) => setActiveTab(value)}
        textColor="#fff"
        scrollButtons="auto"
        variant="scrollable"
      >
        <Tab
          value="edit_profile"
          label="Profile"
          sx={{ color: activeTab === "edit_profile" ? "#fff" : "#BEBEB5" }}
        />
        <Tab
          value="activity"
          label="Activity"
          sx={{ color: activeTab === "activity" ? "#fff" : "#BEBEB5" }}
        />
        <Tab
          value="history"
          label="History"
          sx={{ color: activeTab === "history" ? "#fff" : "#BEBEB5" }}
        />
        <Tab
          value="library"
          label="Library"
          sx={{ color: activeTab === "library" ? "#fff" : "#BEBEB5" }}
        />
      </Tabs>
      {activeTab === "edit_profile" && (
        <EditProfile
          profileData={profileData}
          updateProfileData={updateProfileData}
          isLoading={isLoading}
          editable={editable}
        />
      )}
      {activeTab === "activity" && <MyActivities />}
      {activeTab === "history" && <TransactionHistory />}
    </Box>
  );
};

export default ProfileBody;
