import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

import Header from "../Header";
import BackButton from "../BackButton";
import MobileFooter from "../MobileFooter";
import FooterHome from "../footer/FooterHome";
import { bgImg } from "./PageWrapper";
import ImageSlider from "./ImageSlider";
//ImagePageContainer
const ImagePageContainer = ({
  children,
  backgroundImage = "",
  projects = [],
  showImagesInSlider = false,
  maxWidth = "1042px",
}) => {
  const { palette, ...theme } = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down("sm") || theme.breakpoints.down("xs")
  );

  return (
    <Box sx={{ ...bgImg, minHeight: "100vh" }}>
      <Box
        sx={{
          position: "relative",
          height: {
            xs: "50vh",
            md: "60vh",
          },
        }}
      >
        <Header hamburger />
        {!showImagesInSlider && (
          <Box
            sx={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundImage: `url(${backgroundImage})`,
              objectFit: "contain",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              display: "block",
            }}
          />
        )}
        {showImagesInSlider && projects?.length > 0 && (
          <Box
            sx={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              display: "block",
            }}
          >
            <ImageSlider projects={projects} />
          </Box>
        )}
        <Box
          sx={{
            position: "absolute",
            top: "18%",
            left: "3%",
            [theme.breakpoints.down("sm")]: { top: "20%", display: "none" },
            [theme.breakpoints.down("xs")]: { top: "20%", display: "none" },
          }}
        >
          <BackButton backgroundColor="#94E161" />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "20px",
          marginTop: { xs: "25px", lg: "35px" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: maxWidth,
            paddingBottom: "5rem",
          }}
        >
          {children}
        </Box>
      </Box>

      {isMobile ? <MobileFooter /> : <FooterHome />}
    </Box>
  );
};

export default ImagePageContainer;
