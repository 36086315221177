import React from "react";
import { Box, InputBase, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const SearchContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  background: "rgba(0, 0, 0, 0.4)",
  border: "1px solid #5c5c5c",
  borderRadius: "0.5vw",
  padding: "2px 8px",
  width: "100%",
  maxWidth: "220px",
  height: "42px",
  transition: "all 0.3s ease",
  "&:hover": {
    borderColor: "#FFB800",
    boxShadow: "0 0 8px #5c5c5c",
    border: "1px solid #fff",
  },
  "&:focus-within": {
    borderColor: "#FFB800",
    boxShadow: "0 0 8px #5c5c5c",
    border: "1px solid #fff",
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: "200px",
    height: "38px",
    borderRadius: "1.5vw",
  },
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
  color: "#fff",
  width: "100%",
  fontSize: "1rem",
  fontFamily: "var(--font-i_r)",
  "& .MuiInputBase-input": {
    padding: "8px 0",
    "&::placeholder": {
      color: "rgba(255, 255, 255, 0.5)",
      opacity: 1,
    },
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.9rem",
  },
}));

const NftSearchField = ({
  searchQuery,
  setSearchQuery,
  placeholder = "Search by NFT ID...",
}) => {
  const handleClearSearch = () => {
    setSearchQuery("");
  };

  return (
    <SearchContainer>
      <SearchIcon sx={{ color: "#5c5c5c", mr: 1, fontSize: "1.2rem" }} />
      <SearchInput
        placeholder={placeholder}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        inputProps={{ "aria-label": "search nfts" }}
      />
      {searchQuery && (
        <IconButton
          size="small"
          onClick={handleClearSearch}
          sx={{
            color: "rgba(255, 255, 255, 0.7)",
            padding: "2px",
            "&:hover": {
              color: "#fff",
            },
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </SearchContainer>
  );
};

export default NftSearchField;
