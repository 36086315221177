import { Box, Fade } from "@mui/material";
import { ActiveFont, InactiveFont } from "../ui/Texts";

const transactions = [
  {
    date: "Jan 28 2025",
    address: "ofxF1x...05F",
    detail: "Starter",
    currency: "APE",
    remainingBalance: 165.25,
    amount: 10,
    type: "Credits", // 'Credits' or 'Debits'
    status: "Completed", // 'Completed', 'Pending', 'Processing', 'Failed'
  },
  {
    date: "Jan 25 2025",
    address: "ofxF1x...05F",
    detail: "Starter",
    currency: "APE",
    remainingBalance: 165.25,
    amount: 10,
    type: "Credits", // 'Credits' or 'Debits'
    status: "Pending", // 'Completed', 'Pending', 'Processing', 'Failed'
  },
  {
    date: "Jan 24 2025",
    address: "ofxF1x...05F",
    detail: "Starter",
    currency: "APE",
    remainingBalance: 165.25,
    amount: 10,
    type: "Credits", // 'Credits' or 'Debits'
    status: "Processing", // 'Completed', 'Pending', 'Processing', 'Failed'
  },
  {
    date: "Jan 20 2025",
    address: "ofxF1x...05F",
    detail: "Starter",
    currency: "APE",
    remainingBalance: 165.25,
    amount: 10,
    type: "Credits", // 'Credits' or 'Debits'
    status: "Failed", // 'Completed', 'Pending', 'Processing', 'Failed'
  },
];

const groupByDate = transactions.reduce((acc, item) => {
  if (acc[item.date]) {
    acc[item.date].push(item);
  } else {
    acc[item.date] = [item];
  }
  return acc;
}, {});

const total = 12.25;

const getColorForStatus = (status) => {
  switch (status) {
    case "Completed":
      return "#00901F";
    case "Pending":
      return "#FFB800";
    case "Processing":
      return "#FFf";
    case "Failed":
      return "#BF3344";
    default:
      return "#fff";
  }
};

const cellStyle = {
  whiteSpace: "nowrap",
  minWidth: "100px",
  display: "flex",
  justifyContent: "flex-start",
};

const TransactionHistory = () => {
  return (
    <Fade in timeout={800}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "16px 0px",
          gap: "20px",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <ActiveFont text="Total" style={{ fontSize: "18px" }} />
          <InactiveFont text={`${total} APE`} />
          <img
            src="https://ishrostorage.blob.core.windows.net/container-3d/apeCoinLogo.png"
            alt="APE"
            style={{ width: "20px", height: "20px" }}
          />
        </Box>
        {Object.keys(groupByDate).map((key) => (
          <Box
            key={key}
            className="no-scroll"
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              overflowX: "auto",
            }}
          >
            <InactiveFont text={key} style={{ fontSize: "12px" }} />
            {groupByDate[key].map((transaction, i) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <ActiveFont
                  text={transaction?.address ?? ""}
                  style={{ fontSize: "16px" }}
                />
                <ActiveFont
                  text={transaction?.detail ?? ""}
                  style={{
                    fontSize: "16px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    maxHeight: "100px",
                  }}
                />
                <ActiveFont
                  text={`${transaction?.remainingBalance ?? ""} ${
                    transaction?.currency ?? ""
                  }`}
                  style={{ fontSize: "17px", ...cellStyle }}
                />
                <ActiveFont
                  text={`${transaction?.amount ?? ""} ${
                    transaction?.type ?? ""
                  }`}
                  style={{
                    fontSize: "17px",
                    color: "#FFB800",
                    ...cellStyle,
                  }}
                />
                <ActiveFont
                  text={`${transaction?.status ?? ""}`}
                  style={{
                    fontSize: "17px",
                    color: getColorForStatus(transaction?.status ?? ""),
                    ...cellStyle,
                  }}
                />
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Fade>
  );
};

export default TransactionHistory;
