import React, { useState } from "react";
// import { Box } from "@mui/material";
// import HeaderButton from "./HeaderButton";
// import { selectBalance } from "../../slice/balanceSlice";
import { useSelector } from "react-redux";
// import LoginDialog from "../LoginDialog";
import Withdraw from "../../pages/pricing/payment/Withdraw";
import { useAccount, useBalance } from "wagmi";
import ProfileButton from "./ProfileButton";

const ButtonGroup = () => {
  // const balance = useSelector(selectBalance);
  // const [loginPopup, setLoginPopup] = useState(false);
  const [withdrawPopup, setWithdrawPopup] = useState(false);
  // const [mode, setMode] = useState("login");

  // Get wallet connection status and address
  const { address, isConnected } = useAccount();

  // Get native APE balance from ApeChain
  const { data: apeBalance } = useBalance({
    address: address,
    chainId: 33139, // ApeChain mainnet ID
  });

  // Get native APE balance from Curtis testnet if enabled
  const { data: curtisBalance } = useBalance({
    address: address,
    chainId: 33111, // Curtis testnet ID
    enabled: process.env.NEXT_PUBLIC_ENABLE_TESTNETS === "true",
  });

  // const handleLoginPopup = () => {
  //   setLoginPopup(true);
  // };

  const handleWalletPopup = () => {
    setWithdrawPopup(true);
  };

  const handleWithdrawClose = () => {
    setWithdrawPopup(false);
  };

  const formatBalance = (balanceData) => {
    if (!balanceData) return null;
    return parseFloat(balanceData.formatted).toFixed(2);
  };

  // Get the appropriate balance based on the network
  const getCombinedApeBalance = () => {
    if (!isConnected) return null;

    // If testnet is enabled and we have a Curtis balance, show that
    if (process.env.NEXT_PUBLIC_ENABLE_TESTNETS === "true" && curtisBalance) {
      return formatBalance(curtisBalance);
    }

    // Otherwise show mainnet balance
    return formatBalance(apeBalance);
  };

  return (
    <>
      <ProfileButton
        price={getCombinedApeBalance()}
        handleWalletPopup={handleWalletPopup}
      />
    </>
  );
};

export default ButtonGroup;
