import { lazy, useEffect } from "react";
import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import { useAuth } from "./context/authContext";
import NftModelDetailsNew from "./pages/nftModelDetailsNew";
import Profile from "./pages/profile";
import CollectionData from "./pages/CollectionData/projects";
import EmptyPage from "./pages/EmptyPage";
import NotFound from "./pages/notfound/NotFound";
import RouteWrapper from "./components/RouteWrapper";
import { validationService } from "./services/validationService";
import BaycRenders from "./pages/baycRenders";
import { FooterHome, Header, Loader } from "./components";
import MobileFooter from "./components/MobileFooter";
import ComingSoon from "./pages/CommingSoon";
import Privacy from "./pages/terms/Privacy";
import NftDetails from "./pages/user-nfts/NftDetails";
import PublicProfile from "./pages/public_profile";
import useAccountInfo from "./hooks/useAccountInfo";

const ThirdPartyImageEditor = lazy(() => import("./pages/editor/test"));
const AIGenerator = lazy(() => import("./pages/AIGenerator"));
const Creator = lazy(() =>
  import("./components/Menus/dashboard3d/modelCreator")
);
// const FaceFilterAR = lazy(() => import("./pages/FaceFilterAR"));
const LandingPage = lazy(() => import("./pages/landingPage"));
const CompanyProjects = lazy(() => import("./pages/company/projects"));
const Projects = lazy(() => import("./pages/projects"));
const ProjectDetails = lazy(() => import("./pages/projectDetails"));
const Dashboard3d = lazy(() => import("./components/Menus/dashboard3d"));
const MetaGameVR = lazy(() => import("./components/Menus/metaGameVR"));
const DTools = lazy(() => import("./components/Menus/2DTools"));
const ARService = lazy(() => import("./components/Menus/ARService"));
const About = lazy(() => import("./pages/about/about"));
const Tokenomics = lazy(() => import("./pages/tokenomics/tokenomics"));
const Roadmap = lazy(() => import("./pages/roadmap/roadmap"));
const Analytics = lazy(() => import("./components/Menus/analytics"));
const Login = lazy(() => import("./components/Login"));
const NewsPage = lazy(() => import("./pages/news"));
const NewsDetail = lazy(() => import("./pages/news/NewsDetail"));
const MbaRequest = lazy(() => import("./pages/mba-request"));
const TermsAndConditions = lazy(() =>
  import("./pages/terms/TermsAndConditions")
);

const Animation = lazy(() =>
  import("./components/Menus/dashboard3d/animation/animation")
);
const ModelEditor = lazy(() =>
  import("./components/Menus/dashboard3d/modeleditor/index")
);
const MyAccounts = lazy(() => import("./pages/myAccount"));
const Tutorials = lazy(() => import("./components/Menus/tutorials"));
const Chatbot = lazy(() => import("./components/Menus/Chatbot"));
const Faq = lazy(() => import("./pages/faq/faq"));
const Features = lazy(() => import("./pages/features/features"));
const Pricing = lazy(() => import("./pages/pricing/pricing"));
const ContactUs = lazy(() => import("./pages/contactUs"));
const Gaming = lazy(() => import("./pages/gaming/Gaming"));
const CommingSoon = lazy(() => import("./pages/CommingSoon"));
const Payment = lazy(() => import("./pages/pricing/Payment"));

function AuthenticatedRoute() {
  const { checkAccess, isCheckingAccess, loading } = useAuth();

  const { id = null, nftId = "0000000000X" } = useParams();

  useEffect(() => {
    checkAccess(id, nftId);
  }, [id, nftId]);

  if (loading || isCheckingAccess) {
    return <Loader home />;
  }

  return <Outlet />;
}

function LoggedInRoute() {
  const { user } = useAuth();
  const { address } = useAccountInfo();

  if (user?.role === "admin" || !!address) {
    return <Outlet />;
  }

  return <Navigate to={"/official-demo"} replace />; // Stay on the same page
}

const AppRoutes = () => {
  const { user } = useAuth();
  const { role } = user || {};
  const isAdmin = role === "admin" || role === "user";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Hide the site from non-admin users for now
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/login" element={<Login />} />
      <Route path="/render" element={<BaycRenders />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/features" element={<Features />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/contact" element={<About />} />
      <Route path="/tokenomics" element={<Tokenomics />} />
      <Route path="/roadmap" element={<Roadmap />} />

      <Route path="/contactUs" element={<ContactUs />} />
      <Route path="/payment" element={<Payment />} />

      <Route path="/news" element={<NewsPage />} />
      <Route path="/news/:id" element={<NewsDetail />} />
      <Route path="/mba-request" element={<MbaRequest />} />
      <Route path="/u/:address" element={<PublicProfile />} />
      {/* <Route path="/" element={<LandingPage />} /> */}
      <Route
        path="/"
        element={
          // <RouteWrapper
          //   validationFn={(params) =>
          //     validationService.Companies(params.pageSize)
          //   }
          // >
          <CommingSoon />
          // </RouteWrapper>
        }
      />
      {/* <Route
        path="/official-demo"
        element={
          // <RouteWrapper
          //   validationFn={(params) =>
          //     validationService.Companies(params.pageSize)
          //   }
          // >
          <LandingPage />
          // </RouteWrapper>
        }
      /> */}
      <Route path="404" element={<NotFound />} />
      {/* <Route
    path="/:clientId"
    element={<CompanyProjects />}
  /> */}
      {/* old company project */}
      {/* <Route
        path="/:clientId"
        element={
          <RouteWrapper
            validationFn={(params) => validationService.CompanyProjects("yuga")}
          >
            <CompanyProjects />
          </RouteWrapper>
        }
      /> */}
      <Route path="/official-demo" element={<CompanyProjects />} />
      {/* Protected Routes that need NFT verification */}
      <Route element={<AuthenticatedRoute />}>
        {/* Collection Routes */}
        <Route path="/:clientId/collection/:id" element={<CollectionData />} />
        <Route path="/:clientId/collection" element={<EmptyPage />} />

        {/* NFT Model Routes */}
        {/* <Route
      path="/:clientId/:id/:nftId"
      element={<NftModelDetailsNew />}
    /> */}
        <Route
          path="/:clientId/:id/:nftId"
          element={
            <RouteWrapper
              validationFn={(params) =>
                validationService.getNftById(params.nftId, params.id)
              }
            >
              <NftModelDetailsNew />
            </RouteWrapper>
          }
        />
        <Route
          path="/:clientId/:id/:nftId/3dTools"
          element={
            <RouteWrapper
              validationFn={(params) =>
                validationService.getModelData(params.nftId, params.id)
              }
            >
              <Dashboard3d />
            </RouteWrapper>
          }
        />
        <Route
          path="/:clientId/:id/:nftId/dashboard3d"
          element={<Animation />}
        />
        <Route
          path="/:clientId/:id/:nftId/dashboard3D/editor"
          element={<ModelEditor />}
        />
        <Route
          path="/:clientId/:id/:nftId/3dTools/editor"
          element={<ModelEditor />}
        />
        <Route
          path="/:clientId/:id/:nftId/3dTools/merch"
          element={<Creator />}
        />
        <Route
          path="/:clientId/:id/:nftId/3dTools/MBACloset"
          element={<Creator />}
        />
        <Route
          path="/:clientId/:id/:nftId/2DTools/imageEditor"
          element={<ThirdPartyImageEditor />}
        />
        <Route
          path="/:clientId/:id/:nftId/2DTools/AiGenerated"
          element={<AIGenerator />}
        />
        <Route path="/:clientId/:id/:nftId/files" element={<MetaGameVR />} />
        <Route path="/:clientId/:id/:nftId/2DTools" element={<DTools />} />
        <Route path="/:clientId/:id/:nftId/chatbot" element={<Chatbot />} />
        <Route path="/:clientId/:id/:nftId/arTools" element={<ARService />} />
        <Route path="/:clientId/:id/:nftId/analytics" element={<Analytics />} />
        <Route path="/:clientId/:id/:nftId/tutorials" element={<Tutorials />} />

        {/* Other Protected Routes */}
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:id" element={<ProjectDetails />} />

        <Route
          path="/:clientId/:id"
          element={
            <RouteWrapper
              validationFn={(params) =>
                validationService.clientProjectsDetails(
                  params.id,
                  params.clientId
                )
              }
            >
              <NftDetails />
            </RouteWrapper>
          }
        />
        <Route path="/modeleditor" element={<ModelEditor />} />
        <Route path="/myAccounts" element={<MyAccounts />} />
        <Route path="/games/:gameName" element={<Gaming />} />
      </Route>
      <Route element={<LoggedInRoute />}>
        <Route path="/profile" element={<Profile />} />
      </Route>

      {/* Catch all route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
