import React, { useRef, useState, useEffect } from "react";
import { Box, Button, Grow, Typography, useMediaQuery } from "@mui/material";
import { PiArrowFatLeft, PiArrowFatRight } from "react-icons/pi";
import ImageBoxWithText from "../../components/ImageBoxWithText";
import { NftContactLinks } from "../../components";
import NftSearchField from "./NftSearchField";

const MobileNftDetails = ({
  projectDetail,
  collecttionData,
  nfts,
  pathname,
  getCompanyName,
  palette,
  theme,
}) => {
  const scrollContainerRef = useRef(null);
  const isSmall = useMediaQuery("(max-width:900px)");

  // Search state
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredNfts, setFilteredNfts] = useState(nfts || []);

  // Update filtered NFTs when search query changes or when nfts data changes
  useEffect(() => {
    if (!nfts) return;

    if (searchQuery.trim() === "") {
      setFilteredNfts(nfts);
    } else {
      const filtered = nfts.filter((nft) =>
        nft.nftId.toString().includes(searchQuery.trim())
      );
      setFilteredNfts(filtered);
    }
  }, [searchQuery, nfts]);

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === "left" ? -200 : 200;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box>
      <img
        style={{
          width: "90%",
          marginInline: "auto",
          height: "auto",
          backgroundSize: "contain",
          objectFit: "cover",
          display: "flex",
          borderRadius: "20px",
          marginTop: "10vh",
        }}
        alt={projectDetail?.name}
        src={projectDetail?.sliderImg}
        loading="lazy"
      />
      <Box
        sx={{
          width: "90%",
          display: "flex",
          marginInline: "auto",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 1,
        }}
      >
        <Box
          sx={{
            width: "50%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              textTransform: "capitalize",
              fontFamily: "var(--font-f_r)",
              fontSize: "1.8vw",
              color: palette.grey[200],
              [theme.breakpoints.down("md")]: {
                fontSize: "28px",
              },
              [theme.breakpoints.down("sm")]: {
                fontSize: "22px",
              },
            }}
          >
            {collecttionData?.name}
          </Typography>
          <Button
            sx={{
              color: palette.button.default,
              backgroundColor: "#000000EB",
              border: "2px solid #FFFFFFD4",
              height: "3em",
              fontWeight: "700",
              fontSize: "0.6vw",
              borderRadius: "0.4vw",
              textTransform: "uppercase",
              [theme.breakpoints.down("md")]: {
                height: "3em",
                fontSize: "8px",
                width: "12em",
              },
              [theme.breakpoints.down("sm")]: {
                height: "3em",
                fontSize: "8px",
                width: "12em",
              },
              "&:hover": {
                borderColor: "#FFFFFFD4",
              },
            }}
            variant="outlined"
          >
            by {getCompanyName(pathname?.split("/")[1])}
          </Button>
        </Box>
        <Box
          sx={{
            width: "30%",
          }}
          display="flex"
          justifyContent="space-between"
        >
          <NftContactLinks
            connections={collecttionData.connections}
            topName="LINKS"
            disableName={true}
            key="xxx"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "wrap",
          mt: 1,
        }}
      >
        {collecttionData.prices.map((meta, index) => {
          return (
            <Box
              key={meta.id + "x"}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "#1E1E1E",
                borderRadius: "10px",
                px: 1,
                py: 1,
                m: 1,
                width: {
                  xs: "27.5vw",
                  lg: "27.5vw",
                },
                height: { xl: "8vh", lg: "3vh" },
                boxSizing: "border-box",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontFamily: "var(--font-f_r)",
                  textTransform: "capitalize",
                  textAlign: "center",
                  color: "#fff",
                  opacity: 1,
                  fontSize: "14px",
                }}
              >
                {meta.value}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontFamily: "var(--font-i_r)",
                  textTransform: "capitalize",
                  padding: "0 6px",
                  fontSize: "10px",
                  textAlign: "center",
                  color: "#fff",
                  opacity: 0.7,
                }}
              >
                {meta.name}
              </Typography>
            </Box>
          );
        })}
      </Box>

      <Typography
        sx={{
          paddingLeft: "20px",
          marginBlock: "40px",
          fontWeight: "700",
          fontFamily: "var(--font-i_r)",
          textTransform: "capitalize",
          color: palette.font.default,
          opacity: 1,
          margin: "2px 8px 0px 2px",
          padding: "8px",
          fontSize: "14px",
        }}
      >
        {collecttionData?.description}
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          width: "90%",
          marginInline: "auto",
          mt: 2,
          mb: 2,
          [theme.breakpoints.down("sm")]: {
            justifyContent: "space-between",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "var(--font-i_r)",
            color: palette.font.default,
            fontSize: "14px",
          }}
        >
          Choose your asset
        </Typography>
        <NftSearchField
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </Box>

      <Box
        ref={scrollContainerRef}
        sx={{
          display: "flex",
          width: "100%",
          overflowX: "scroll",
          gap: 0,
          padding: 0,
          paddingLeft: { xs: "4vw" },
          "::-webkit-scrollbar": {
            display: "none !important",
          },
        }}
      >
        {filteredNfts && filteredNfts.length > 0 ? (
          filteredNfts.map((nftModel) => (
            <ImageBoxWithText
              key={nftModel.nftId}
              id={nftModel.nftId}
              imageUrl={nftModel.image}
              navigateTo={`${nftModel.nftId}`}
              sx={{
                flex: "1 1 300px",
                maxWidth: "300px",
                margin: 0,
                padding: 0,
              }}
            />
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "90%",
              marginLeft: "5%",
              padding: 3,
              color: "#fff",
              borderRadius: "10px",
              backgroundColor: "rgba(0,0,0,0.4)",
              border: "1px solid rgba(255,184,0,0.3)",
            }}
          >
            <Typography align="center">
              No NFTs found with ID: {searchQuery}
            </Typography>
          </Box>
        )}
      </Box>
      {filteredNfts?.length > 2 && (
        <Grow
          in
          timeout={{
            appear: 3000,
            enter: 3000,
            exit: 3000,
          }}
          mountOnEnter
          appear={true}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              scale: "1.4",
              transition: "all .4s ease-in-out",
            }}
          >
            <PiArrowFatLeft color="#fff" onClick={() => scroll("left")} />

            <Typography
              sx={{
                margin: "10px",
                fontWeight: "600",
                fontFamily: "var(--font-i_r)",
                textTransform: "capitalize",
                color: "#FFB800",
                textAlign: "center",
                fontSize: "10px",
              }}
            >
              Slide to see more
            </Typography>
            <PiArrowFatRight color="#fff" onClick={() => scroll("right")} />
          </Box>
        </Grow>
      )}
      <Box sx={{ height: "15vh" }} />
    </Box>
  );
};

export default MobileNftDetails;
