// src/api.js
import axios from "axios";
const BASEURL = "https://node-api.dashbo.xyz/v1/";
// export const BASEURL = "http://localhost:3001/v1/";

const instance = axios.create({
  baseURL: BASEURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// Add request interceptor for debugging
instance.interceptors.request.use(
  (config) => {
    return config;

  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

// Add response interceptor for debugging
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
