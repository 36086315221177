import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const HeroSlider = ({ projects = [] }) => {
  const { palette, ...theme } = useTheme();
  const navigate = useNavigate();

  const Projects = [...projects].map((project, i) => (
    <Box
      key={i}
      onClick={() => {
        navigate(`${project.nftId}`);
      }}
      sx={{
        margin: "0.5vw",
        cursor: "pointer",
        width: "19%",
        display: "flex",
        flexDirection: "column",
        backdropFilter: "blur(10px)",
        height: { xl: "21vh", lg: "20vh" },
        justifyContent: "center",
        border: "1px solid #95959529",
        p: { xl: 1, lg: 2 },
        m: 1,
        alignItems: "center",
        borderRadius: "0.5vw",
        ".text-banner-pro": {},
        img: {
          display: "block",
          width: "100%",
          height: "auto",
          objectFit: "cover",
          objectPosition: "top",
          borderRadius: "12px",
        },
        transition:
          "transform 0.3s ease, border 0.3s ease, box-shadow 0.3s ease",

        "&:hover": {
          transform: "scale(1.02)",
          border: "1px solid #FFB800",
          boxShadow: "0 4px 8px rgba(255, 184, 0, 0.5)",

          ".text-banner-pro": {
            color: "#FFB800",
          },
        },
      }}
    >
      <img src={project.image} alt={project?.name} loading="lazy" />
      <Box>
        <Typography
          className="text-banner-pro"
          sx={{
            margin: 0,
            fontWeight: "700",
            textTransform: "capitalize",
            fontFamily: "var(--font-i_r)",
            padding: 1,
            fontSize: {
              xl: "1.2em",
              lg: "0.9em",
              md: "0.6em",
              xs: "0.7em",
              sm: "0.7em",
            },
            color: palette.grey[200],
          }}
        >
          {project?.nftId}
        </Typography>
      </Box>
    </Box>
  ));

  return (
    <Box>
      <Box
        sx={{
          margin: 0,
          display: "flex",
          flexWrap: "wrap",
          maxHeight: "40vh",
          width: "100%",
          overflowY: "scroll",
          [theme.breakpoints.down("lg")]: {},
        }}
      >
        {Projects}
      </Box>
    </Box>
  );
};

export default HeroSlider;
