import { Box } from "@mui/system";

import ImagePageContainer from "../../components/common/ImagePageContainer";
import ProfileBody from "../../components/profile/ProfileBody";
import ProfileHeader from "../../components/profile/ProfileHeader";
import useProfileData from "../../hooks/useProfileData";
import useAccountInfo from "../../hooks/useAccountInfo";

const Profile = () => {
  const { address } = useAccountInfo();
  const { profileData, updateProfileData, isLoading } = useProfileData(address);

  return (
    <ImagePageContainer
      backgroundImage="https://storage.googleapis.com/3d-container/Pose_001%201.jpg"
      maxWidth="1260px"
    >
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          flexDirection: { xs: "column", lg: "row" },
          alignItems: { xs: "center", lg: "flex-start" },
        }}
      >
        <ProfileHeader
          profileData={profileData}
          updateProfileData={updateProfileData}
          isLoading={isLoading}
          editable={
            profileData?.role === "admin" ||
            profileData?.walletAddress?.toLocaleLowerCase() ===
              address?.toLocaleLowerCase()
          }
        />
        <ProfileBody
          profileData={profileData}
          updateProfileData={updateProfileData}
          isLoading={isLoading}
          editable={
            profileData?.role === "admin" ||
            profileData?.walletAddress?.toLocaleLowerCase() ===
              address?.toLocaleLowerCase()
          }
        />
      </Box>
    </ImagePageContainer>
  );
};

export default Profile;
