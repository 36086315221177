import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ImageBoxWithText = ({ id, imageUrl, navigateTo, sx }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(navigateTo);
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        cursor: "pointer",
        width: "220px",
        minWidth: "180px",
        marginTop: "5px",
        flexDirection: "row",
        "&:hover": {},
        ...sx,
      }}
    >
      <div
        style={{
          margin: "2px 8px 2px 0px",
          padding: "0",
          backgroundColor: "transparent",
          borderRadius: "12px",
        }}
      >
        <img
          src={imageUrl}
          alt={`Image ${id}`}
          style={{ width: "100%", maxWidth: "220px", borderRadius: "12px" }} 
        />
        <Typography
          sx={{
            fontWeight: "bold",
            color: "white",
            fontWeight: "600",
            fontFamily: "var(--font-i_r)",
            fontSize: "16px",
          }}
        >
          # {id}
        </Typography>
      </div>
    </Box>
  );
};

export default ImageBoxWithText;
