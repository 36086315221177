import { useTheme } from "@emotion/react";
import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BsDiscord } from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";
import { FiGlobe } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import { etherScan, ship } from "../../assets/images";
import { Header } from "../../components";
import FooterHome from "../../components/footer/FooterHome";
import MobileFooter from "../../components/MobileFooter";
import "../../components/shared.css";
import { clientProjectsDetails } from "../../services/clientService";
import {
  formatNumberIntoCurrency,
  formatNumberWithoutMeasure,
} from "../../utils";
import MobileNftDetails from "./MobileNftDetails";
import DesktopNftDetails from "./DesktopNftDetails";
import { PageLoader } from "../../components/common/PageLoader";

const NftDetails = () => {
  const { palette, ...theme } = useTheme();
  const { id, clientId } = useParams();
  const [projectDetail, setProjectDetail] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [nfts, setNfts] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery("(max-width:599px)");
  const isSmall = useMediaQuery("(max-width:900px)");

  const getClientProjectData = async () => {
    try {
      setIsLoading(true);
      const { data } = await clientProjectsDetails(id, clientId);
      if (data) {
        setProjectDetail(data?.projectObj);
        getCollectionMetaData(data?.projectObj);
        setNfts(data?.projectObj.nftModels || []);
      }
    } catch (err) {
      if (err.status === 404) {
        navigate("/404");
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getCompanyName = (name) => {
    switch (name) {
      case "yuga":
        return "yugalabs";
      case "Quirkies":
        return "quirkiesoriginals";
      case "delabs":
        return "De [ Labs ]";
      case "AKCB":
        return "akcb";
      case "RTFKT":
        return "RTFKT";
      case "Azuki":
        return "Azuki";
      case "ImmaVerse":
        return "ImmaVerse";
      case "AMGI":
        return "AMGI";
      case "on1":
        return "on1";
      case "Others":
        return "Others";
      default:
        return "Others";
    }
  };

  const [collecttionData, setCollectionData] = useState({
    name: "",
    image: "",
    description: "",
    connections: [
      {
        id: 0,
        icon: (
          <FiGlobe
            size={isSmall ? "20px" : "1vw"}
            width={"16px"}
            color="#fff"
          />
        ),
        onClick: () => {},
      },
      {
        id: 1,
        icon: (
          <BsDiscord
            size={isSmall ? "20px" : "1vw"}
            width={"16px"}
            color="#fff"
          />
        ),
        onClick: () => {},
      },
      {
        id: 2,
        icon: (
          <FaXTwitter
            size={isSmall ? "20px" : "1vw"}
            width={"16px"}
            color="#fff"
          />
        ),
        onClick: () => {},
      },
      {
        id: 3,
        icon: (
          <img src={etherScan} alt="ethersacn" width={"16px"} loading="lazy" />
        ),
        onClick: () => {},
      },
    ],
    buyNowConnections: [
      {
        id: 0,
        icon: (
          <img
            style={{ width: isSmall ? "16px" : "1vw" }}
            src={"https://storage.googleapis.com/3d-container/magiceden.png"}
            alt="magicceden"
            loading="lazy"
          />
        ),
        onClick: () => {},
      },
      {
        id: 1,
        icon: (
          <img
            style={{ width: isSmall ? "16px" : "1vw" }}
            src={ship}
            alt="ship"
            loading="lazy"
          />
        ),
        onClick: () => {},
      },
      {
        id: 2,
        icon: (
          <img
            src="https://ishrostorage.blob.core.windows.net/container-3d/Looks%20rare.png"
            alt="lookrare"
            style={{ width: isSmall ? "16px" : "1vw" }}
            loading="lazy"
          />
        ),
        onClick: () => {},
      },
      {
        id: 3,
        icon: (
          <img
            style={{ width: isSmall ? "16px" : "1vw" }}
            src="https://ishrostorage.blob.core.windows.net/container-3d/Blur icon.png"
            alt="blur"
            loading="lazy"
          />
        ),
        onClick: () => {},
      },
    ],
    metaData: [],
    prices: [],
  });

  const getCollectionMetaData = async (response) => {
    try {
      const connections = [
        {
          id: 1,
          icon: <FiGlobe size={isSmall ? "16px" : "1vw"} color="#fff" />,
          onClick: () => {
            window.open(response?.web, "_blank");
          },
        },
        {
          id: 2,
          icon: <BsDiscord size={isSmall ? "16px" : "1vw"} color="#fff" />,
          onClick: () => {
            window.open(response?.discord, "_blank");
          },
        },
        {
          id: 3,
          icon: <FaXTwitter size={isSmall ? "16px" : "1vw"} color="#fff" />,
          onClick: () => {
            window.open(response?.twitter, "_blank");
          },
        },
        {
          id: 4,
          icon: (
            <img
              style={{ width: isSmall ? "16px" : "1vw" }}
              src={etherScan}
              alt="etherscan"
              loading="lazy"
            />
          ),
          onClick: () => {
            window.open(response?.etherScan, "_blank");
          },
        },
      ];
      const buyNowConnections = [
        {
          id: 1,
          icon: (
            <img
              style={{ width: isSmall ? "16px" : "1vw" }}
              src={"https://storage.googleapis.com/3d-container/magiceden.png"}
              alt="magicceden"
              loading="lazy"
            />
          ),
          onClick: () => {
            window.open(response?.magiceden, "_blank");
          },
        },
        {
          id: 2,
          icon: (
            <img
              style={{ width: isSmall ? "16px" : "1vw" }}
              src={ship}
              alt="ship"
              loading="lazy"
            />
          ),
          onClick: () => {
            window.open(response?.openSea, "_blank");
          },
        },
        {
          id: 3,
          icon: (
            <img
              style={{ width: isSmall ? "16px" : "1vw" }}
              src="https://ishrostorage.blob.core.windows.net/container-3d/Looks%20rare.png"
              alt="lookrare"
              loading="lazy"
            />
          ),
          onClick: () => {
            window.open(response?.looksRare, "_blank");
          },
        },
        {
          id: 4,
          icon: (
            <img
              style={{ width: isSmall ? "16px" : "1vw" }}
              src="https://ishrostorage.blob.core.windows.net/container-3d/Blur icon.png"
              alt="blur"
              loading="lazy"
            />
          ),
          onClick: () => {
            window.open(response?.blue, "_blank");
          },
        },
      ];

      const metaData = [
        {
          name: "items",
          value: formatNumberWithoutMeasure(response?.tokenCount || 0),
        },
        {
          name: "Owner Count",
          value: formatNumberWithoutMeasure(response?.ownerCount || 0),
        },
        {
          name: "chain",
          value: response?.chain,
        },
      ];

      const prices = [
        {
          name: "items",
          id: "p1",
          value: formatNumberIntoCurrency(response?.tokenCount || 0),
        },
        {
          name: "Owner Count",
          value: formatNumberIntoCurrency(response?.ownerCount || 0),
          id: "p2",
        },
        {
          name: "chain",
          value: response?.chain,
          id: "p3",
        },
        {
          name: "Total volume",
          value: formatNumberIntoCurrency(response?.volume),
          id: "p4",
        },
        {
          name: "Floor Price",
          value: formatNumberWithoutMeasure(response?.floorPrice),
          id: "p5",
        },
        {
          name: "Top Bid",
          value: formatNumberWithoutMeasure(response?.topBid),
          id: "p6",
        },
      ];
      setCollectionData({
        name: response?.name,
        image: response?.image,
        description: response?.description,
        connections,
        buyNowConnections,
        metaData,
        prices,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getClientProjectData();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <Box
        sx={{
          zIndex: 1000,
          overflowY: "hidden",
          [theme.breakpoints.down("sm")]: {
            overflowY: "clip",
          },
        }}
      >
        <Header hamburger={true} />

        {isSmall ? (
          <MobileNftDetails
            projectDetail={projectDetail}
            collecttionData={collecttionData}
            nfts={nfts}
            pathname={pathname}
            getCompanyName={getCompanyName}
            palette={palette}
            theme={theme}
          />
        ) : (
          <DesktopNftDetails
            projectDetail={projectDetail}
            collecttionData={collecttionData}
            nfts={nfts}
            pathname={pathname}
            getCompanyName={getCompanyName}
            palette={palette}
            theme={theme}
            navigate={navigate}
          />
        )}

        {isMobile ? <MobileFooter /> : <FooterHome />}
      </Box>
    </>
  );
};

export default NftDetails;
