import { useEffect, useState } from "react";
import { IoChevronDownSharp, IoChevronForward } from "react-icons/io5";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { fetchProfile } from "../../services/clientService";
import { InactiveFont } from "../ui/Texts";
import useAccountInfo from "../../hooks/useAccountInfo";
import { useAuth } from "../../context/authContext";

const ProfileButton = ({ price, handleWalletPopup }) => {
  const { logout } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate(); // Navigation hook
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { address, shortAddress } = useAccountInfo();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { data } = await fetchProfile(address ?? null);
      setProfileData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCopy = () => {
    if (address) {
      navigator.clipboard.writeText(address);
      enqueueSnackbar("Copied!", { variant: "success" });
    }
  };

  const handleEditProfile = () => {
    navigate("/profile"); // Navigate to the profile page
    handleCloseUserMenu(); // Close the menu
  };

  const handleLogout = () => {
    logout();
    enqueueSnackbar("Logged out successfully!", {
      variant: "info",
      style: { color: "#000", backgroundColor: "#FFB800", marginTop: "50px" },
    });
    handleCloseUserMenu(); // Close the menu
  };

  const handleClickOnCredit = () => {
    handleCloseUserMenu(); // Close the menu
    handleWalletPopup();
  };

  if (isLoading) {
    return null;
  }

  const ProfileAvatar = () => (
    <Avatar
      sx={{ height: "30px", width: "30px" }}
      alt={profileData?.firstName ?? "User"}
      src={
        profileData?.profileImage ??
        "https://ishrostorage.blob.core.windows.net/container-3d/MAYC%20banner.png"
      }
    />
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          marginRight: "8px",
          color: "#fff",
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          padding: { xs: "4px", sm: "8px" },
          borderRadius: "4px",
          cursor: "pointer",
        }}
        onClick={handleOpenUserMenu}
      >
        {profileData && profileData?.role === "user" && price && (
          <Typography
            sx={{
              "&:hover": theme.palette.button.default,
              whiteSpace: "nowrap",
            }}
          >{`${price} APE`}</Typography>
        )}
        {profileData && profileData?.role === "admin" && (
          <Typography
            sx={{
              "&:hover": theme.palette.button.default,
              whiteSpace: "nowrap",
            }}
          >{`admin`}</Typography>
        )}
        <ProfileAvatar />
        <IoChevronDownSharp size={24} />
      </Box>
      <Menu
        sx={{
          mt: "45px",
          "& .MuiPaper-root": {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        MenuListProps={{
          sx: {
            backgroundColor: "#000",
            display: "flex",
            padding: "16px",
            flexDirection: "column",
            gap: "11px",
            borderRadius: "20px",
            border: "1px solid #FFB800",
            backdropFilter: "blur(20px)",
          },
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Box
          sx={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
              padding: "9px 10px",
            }}
          >
            <ProfileAvatar />
            <InactiveFont
              text={
                profileData?.firstName || profileData?.lastName
                  ? `${profileData?.firstName ?? ""} ${
                      profileData?.lastName ?? ""
                    }`
                  : shortAddress
              }
            />
          </Box>
          <img
            src="https://storage.googleapis.com/3d-container/NFTDetailPageIcons/copyIcon.svg"
            style={{ paddingLeft: "4px", cursor: "pointer" }}
            onClick={handleCopy}
            alt=""
          />
        </Box>
        <MenuItem
          sx={{
            padding: "9px 10px",
            borderRadius: "8px",
            backgroundColor: "#0D0D0D",
            backdropFilter: "blur(20px)",
            display: "flex",
            justifyContent: "space-between",
          }}
          onClick={handleClickOnCredit}
        >
          <InactiveFont text={`${profileData?.credits ?? "0.00"} Credits`} />
          <img
            src="https://storage.googleapis.com/3d-container/BalanceIcon.png"
            alt="Icon"
            style={{ width: "25px", height: "25px" }}
          />
        </MenuItem>
        <MenuItem
          sx={{
            padding: "9px 10px",
            borderRadius: "8px",
            backgroundColor: "#0D0D0D",
            backdropFilter: "blur(20px)",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
          onClick={handleEditProfile}
        >
          <InactiveFont text="Profile" />
          <IoChevronForward size={24} color="#909090" />
        </MenuItem>
        <MenuItem
          sx={{
            padding: "9px 10px",
            borderRadius: "8px",
            backgroundColor: "#0D0D0D",
            backdropFilter: "blur(20px)",
          }}
          onClick={handleLogout}
        >
          <InactiveFont text="Logout" />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ProfileButton;
