import { Box, Fade } from "@mui/material";
import { LuArrowDownUp } from "react-icons/lu";
import { ActiveFont, InactiveFont } from "../ui/Texts";

const activityData = [
  {
    date: "Jan 28 2025",
    detail: "Bayc dance animation, environment realistic red bayc lab",
    amount: 15,
    currency: "APE",
    remainingBalance: 165.25,
  },
  {
    date: "Jan 28 2025",
    detail: "Bayc Kick pose and mutant music",
    amount: 15,
    currency: "APE",
    remainingBalance: 150.25,
  },
  {
    date: "Jan 20 2025",
    detail: "Bayc dance animation, environment realistic red bayc lab",
    amount: 15,
    currency: "APE",
    remainingBalance: 135.25,
  },
  {
    date: "Jan 20 2025",
    detail: "Bayc Kick pose and mutant music",
    amount: 15,
    currency: "APE",
    remainingBalance: 135.25,
  },
];

const groupByDate = activityData.reduce((acc, item) => {
  if (acc[item.date]) {
    acc[item.date].push(item);
  } else {
    acc[item.date] = [item];
  }
  return acc;
}, {});

const total = 12.25;

const MyActivities = () => {
  return (
    <Fade in timeout={800}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "16px 0px",
          gap: "20px",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <ActiveFont text="Total" style={{ fontSize: "18px" }} />
          <InactiveFont text={`${total} APE`} />
          <img
            src="https://ishrostorage.blob.core.windows.net/container-3d/apeCoinLogo.png"
            alt="APE"
            style={{ width: "20px", height: "20px" }}
          />
        </Box>
        {Object.keys(groupByDate).map((key) => (
          <Box
            key={key}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <InactiveFont text={key} style={{ fontSize: "12px" }} />
            {groupByDate[key].map((transaction, i) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LuArrowDownUp color="#BEBEB5" size={20} />
                  </Box>
                  <ActiveFont
                    text={transaction?.detail ?? ""}
                    style={{
                      fontSize: "16px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      maxHeight: "100px",
                    }}
                  />
                </Box>
                <ActiveFont
                  text={`${transaction?.amount ?? ""} ${
                    transaction?.currency ?? ""
                  }`}
                  style={{ fontSize: "17px", color: "#FFB800" }}
                />
                <ActiveFont
                  text={`${transaction?.remainingBalance ?? ""} ${
                    transaction?.currency ?? ""
                  }`}
                  style={{ fontSize: "17px", color: "#FFB800" }}
                />
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Fade>
  );
};

export default MyActivities;
