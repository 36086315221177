import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import { Snackbar, SnackbarContent } from "@mui/material";
import html2canvas from "html2canvas";
import { enqueueSnackbar } from "notistack";
import React from "react";
export function capitalizeFirstWord(str) {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
}
export const STORAGE_URL = "https://storage.googleapis.com/3d-container/";
// export const BASEURL = "https://dashbo-432309.ue.r.appspot.com/v1/";
// export const BASEURL = "https://bayccollection.ue.r.appspot.com/v1/";
export const BASEURL = "http://localhost:3001/v1/";
const ffmpeg = new FFmpeg();
export function showToast(type, message, setToast) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={true}
      autoHideDuration={5000}
      onClose={() => setToast(false)}
    >
      <SnackbarContent
        style={{
          backgroundColor: type === "success" ? "green" : "red",
        }}
        message={message}
      />
    </Snackbar>
  );
}

export const downloadBlobByUrl = (blob, fileName) => {
  const a = document.createElement("a");
  a.href = blob;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export function copyToClipboard(text, copyText = "Copied!") {
  if (!navigator.clipboard) {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "fixed";
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    enqueueSnackbar(copyText, {
      variant: "success",
    });
  } else {
    // Clipboard API supported
    navigator.clipboard
      .writeText(text)
      .then(() => {
        enqueueSnackbar(copyText, {
          variant: "success",
        });
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
        alert("Failed to copy to clipboard");
      });
  }
}

export async function startRecording(
  id,
  fileName,
  setRecording,
  type,
  setPreviewContent,
  duration = 10,
  audioRef,
  isPlaying,
  setConverting,
  downloadType,
  nftId,
  slog
) {
  setRecording(true);
  try {
    const chunks = [];
    let canvasElt = document.getElementById(id);
    let canvasElt1 = canvasElt?.firstChild;

    // Ensure proper color space and pixel format
    const ctx = canvasElt1.getContext("2d", {
      colorSpace: "srgb",
      willReadFrequently: true,
    });

    const videoStream = canvasElt1.captureStream(60);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    let combinedStream;
    if (isIOS) {
      const mimeType = "video/webm;codecs=vp8,opus";
      const fallbackMimeType = "video/mp4;codecs=avc1.42E01E,mp4a.40.2";
      const selectedMimeType = MediaRecorder.isTypeSupported(mimeType)
        ? mimeType
        : MediaRecorder.isTypeSupported(fallbackMimeType)
        ? fallbackMimeType
        : "video/webm";
      if (isPlaying && audioRef.current) {
        try {
          const audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
          const source = audioContext.createMediaElementSource(
            audioRef.current
          );
          const destination = audioContext.createMediaStreamDestination();
          source.connect(destination);
          source.connect(audioContext.destination);

          combinedStream = new MediaStream([
            ...videoStream.getVideoTracks(),
            ...destination.stream.getAudioTracks(),
          ]);
        } catch (e) {
          console.warn("Failed to capture audio stream:", e);
          combinedStream = new MediaStream([...videoStream.getVideoTracks()]);
        }
      } else {
        combinedStream = new MediaStream([...videoStream.getVideoTracks()]);
      }

      const recorderOptions = {
        mimeType: selectedMimeType,
        videoBitsPerSecond: 2500000, // 2.5 Mbps
        audioBitsPerSecond: 128000, // 128 kbps
      };

      const rec = new MediaRecorder(combinedStream, recorderOptions);

      rec.onerror = (e) => {
        console.error("MediaRecorder error:", e);
        setRecording(false);
      };

      rec.ondataavailable = (e) => chunks.push(e.data);
      rec.onstop = async (e) => {
        const blob = new Blob(chunks, { type: "video/mp4" });
        const url = URL.createObjectURL(blob);
        const extension = selectedMimeType.includes("mp4") ? "mp4" : "webm";

        if (type === "onlyRecord") {
          setRecording(false);
          setPreviewContent(blob);
        } else {
          setRecording(false);

          // Create a container for preview and download
          const container = document.createElement("div");
          container.style.cssText = `
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #000;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 0 10px rgba(0,0,0,0.3);
          z-index: 9999;
          max-width: 90vw;
          max-height: 90vh;
          overflow: auto;
          `;

          // Add video preview
          const video = document.createElement("video");
          video.src = url;
          video.controls = true;
          video.style.maxWidth = "100%";
          video.style.marginBottom = "15px";
          container.appendChild(video);

          // Add download button
          const downloadBtn = document.createElement("button");
          downloadBtn.textContent = "Download Video";
          downloadBtn.style.cssText = `
            display: block;
            width: 100%;
            padding: 10px;
            border:"1px solid #fff";
            blackground: #000 !important;
            color: #000;
            border-radius: 5px;
            cursor: pointer;
            margin-bottom: 10px;
            `;

          // Add close button
          const closeBtn = document.createElement("button");
          closeBtn.textContent = "Close";
          closeBtn.style.cssText = `
            display: block;
            width: 100%;
            padding: 10px;
            border:"1px solid #fff";
            blackground: #000;
            color: #000;
            border-radius: 5px;
            cursor: pointer;
            `;

          // Handle download
          downloadBtn.onclick = () => {
            // For iOS, create a temporary link and trigger download
            const tempLink = document.createElement("a");
            tempLink.href = url;
            tempLink.download =
              "DashBO_" + slog + "_" + nftId + "_" + fileName + `.${extension}`;
            tempLink.style.display = "none";
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
          };

          // Handle close
          closeBtn.onclick = () => {
            document.body.removeChild(container);
            URL.revokeObjectURL(url);
          };

          container.appendChild(downloadBtn);
          container.appendChild(closeBtn);
          document.body.appendChild(container);
        }
      };
      rec.start(1000);

      setTimeout(() => {
        rec.stop();
      }, parseInt(duration) * 1000);
    } else {
      if (isPlaying) {
        const audioStream = audioRef.current.captureStream();
        combinedStream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...audioStream.getAudioTracks(),
        ]);
      } else {
        combinedStream = new MediaStream([...videoStream.getVideoTracks()]);
      }
      const recorderOptions = {
        mimeType: GetTouchDevices()
          ? "video/webm;codecs=vp9,opus"
          : "video/webm;codecs=vp9",
        videoBitsPerSecond: 8000000,
        audioBitsPerSecond: 128000,
      };
      videoStream.getVideoTracks().forEach((track) => {
        const capabilities = track.getCapabilities();
        if (capabilities.colorSpace) {
          track.applyConstraints({
            colorSpace: "srgb",
          });
        }
      });
      const rec = new MediaRecorder(combinedStream, recorderOptions);
      rec.ondataavailable = (e) => chunks.push(e.data);
      rec.onstop = async (e) => {
        const blob = new Blob(chunks, { type: "video/mp4" });
        if (type === "onlyRecord") {
          setRecording(false);
          setPreviewContent(blob);
        } else {
          setRecording(false);
          setConverting(true);
          // Do the convertion from api, and then download
          const formData = new FormData();
          formData.append("video", blob, "recording.webm"); // Append the Blob with a filename
          fetch(
            "https://twitter-backend-dot-inceptivestudio-370108.uc.r.appspot.com/convert",
            {
              // fetch("http://localhost:3010/convert", {
              method: "POST",
              body: formData,
            }
          )
            .then((response) => {
              if (!response.ok) {
                throw new Error(`Failed to upload video: ${response.status}`);
              }
              return response.blob();
            })
            .then((convertedBlob) => {
              const url = URL.createObjectURL(convertedBlob);
              const a = document.createElement("a");
              a.href = url;
              a.download =
                "DashBO_" + slog + "_" + nftId + "_" + fileName + ".mp4";
              a.click();
              URL.revokeObjectURL(url);
              setConverting(false);
            })
            .catch((error) => {
              console.error("Error during file upload or conversion:", error);
              setConverting(false);
            });
        }
      };
      rec.start();
      setTimeout(() => {
        rec.stop();
      }, parseInt(duration) * 1000);
    }
  } catch (err) {
    console.log(err, "errerrerrerrerrerrerr");
  }
}
ffmpeg.on("log", ({ type, message }) => {
  console.log({ type, message });
});

export async function convertAndExportVid(
  blob,
  fileName,
  setRecording,
  setConverting,
  downloadType
) {
  try {
    await ffmpeg.load();
    const data = await fetchFile(blob);
    await ffmpeg.writeFile("input.mp4", data);
    setRecording(false);
    setConverting(true);
    const scale = () => {
      switch (downloadType) {
        case "LowRes":
          return "scale=1280:720";
        case "HighRes":
          return "scale=1600:900";
        case "UltraHD":
          return "scale=3840:2160";
      }
    };
    await ffmpeg.exec([
      "-i",
      "input.mp4",
      "-codec",
      "copy", // Speed up conversion
      "-crf",
      "15",
      "-ar",
      "44100",
      "-c:v",
      "libx264", // H.264 codec
      "-pix_fmt",
      "yuv420p", // Ensure QuickTime compatibility
      "-vf",
      scale(), // Maintain resolution (adjust as needed)
      "-preset",
      "ultrafast", // Speed up conversion
      "-movflags",
      "faststart",
      "output.mp4",
    ]);

    // Retrieve the converted file
    const mp4Data = await ffmpeg.readFile("output.mp4");
    console.log(mp4Data);
    // Create a Blob and a download link for the converted video
    const mp4Blob = new Blob([mp4Data], { type: "video/mp4" });
    console.log(mp4Blob);

    const url = URL.createObjectURL(mp4Blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "DashBO_" + fileName + ".mp4";
    a.click();
    setConverting(false);
    URL.revokeObjectURL(url);
  } catch (err) {
    console.log(err);
    setRecording(false);
  }
}

export const validatePhoneNumber = (e) => {
  if (!e?.includes("/")) {
    return true;
  }
  if (e && e.length > 0) {
    const diff = e.split("/");
    const targetValueLength = diff[0].length;
    const requiredLength = (diff[1]?.match(/\./g) || []).length;
    if (requiredLength !== targetValueLength) {
      return false;
    } else if (targetValueLength === requiredLength) {
      return true;
    }
  }
  return false;
};

export function rgba2hex(rgbaString) {
  // Extract the RGBA components from the string
  const match = rgbaString.match(/(\d+(\.\d+)?)/g);
  if (!match || match.length < 4) {
    return null; // Invalid input
  }

  // Convert each component to an integer
  const [r, g, b] = match.map(Number);

  // Convert to hex and format the string
  const rInt = Math.round(r);
  const gInt = Math.round(g);
  const bInt = Math.round(b);
  const hexCode = `#${rInt.toString(16).padStart(2, "0")}${gInt
    .toString(16)
    .padStart(2, "0")}${bInt.toString(16).padStart(2, "0")}`;

  return hexCode;
}

export const downloadImage = (imageUrl, fileName) => {
  fetch(imageUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.style.display = "none";
      anchor.href = blobUrl;
      anchor.download = fileName;

      document.body.appendChild(anchor);
      anchor.click();
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(anchor);
    });
};

export const htmlToImageConvert = async (
  name,
  elementRef,
  setFullScreen = null,
  element = "#videoAnimation"
) => {
  try {
    const data = await html2canvas(document.querySelector(element), {
      allowTaint: true,
      useCORS: true,
      scale: 4,
    });
    var anchorTag = document.createElement("a");
    anchorTag.download = "DashBO_" + (name ? name : "NFT-preview") + ".jpg";
    anchorTag.href = data.toDataURL();
    anchorTag.target = "_blank";
    anchorTag.click();
    if (setFullScreen) {
      exitFullscreen(elementRef, setFullScreen);
    }
  } catch (err) {
    console.log(err);
  }
};

export function formatNumberWithoutMeasure(value) {
  if (typeof value !== "number") {
    return value;
  }
  return value.toLocaleString();
}

export const formatNumberIntoCurrency = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + " M+";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + " K+";
  } else {
    return num.toString();
  }
};

export const getExpressioName = (name) => {
  switch (name) {
    case "EyeClosed":
      return "Eye Closed";
    case "X_Closed":
      return "X Closed";
    case "small_grin":
      return "Small Grin";
    case "Phoneme_vuh":
      return "Phoneme Vuh";
    case "tounge_out":
      return "Tongue Out";
    case "Phoneme_wah":
      return "Phoneme Wah";
    case "Phoneme_oH":
      return "Phoneme oH";
    case "Phoneme_ooo":
      return "Phoneme ooo";
    case "Phoneme_L":
      return "Phoneme L";
    case "Angry_Face":
      return "Angry";
    case "BloodShot":
      return "Blood Shot";
    case "Bored_Face":
      return "Bored";
    default:
      return name;
  }
};

export const handleFullscreen = (elementRef, setFullScreen) => {
  if (elementRef.current) {
    if (elementRef.current.requestFullscreen) {
      elementRef.current.requestFullscreen();
    } else if (elementRef.current.mozRequestFullScreen) {
      // Firefox
      elementRef.current.mozRequestFullScreen();
    } else if (elementRef.current.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      elementRef.current.webkitRequestFullscreen();
    } else if (elementRef.current.msRequestFullscreen) {
      // IE/Edge
      elementRef.current.msRequestFullscreen();
    }
    setFullScreen(true);
  }
};
export const exitFullscreen = (elementRef, setFullScreen) => {
  if (elementRef.current) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      // IE/Edge
      document.msExitFullscreen();
    }
    setFullScreen(false);
  }
};
export const allEyes = {
  Bored_Eyes: [
    "Bored",
    "Bloodshot",
    "3d",
    "Heart",
    "Sunglasses",
    "Robot",
    "Eyepatch",
    "Zombie",
    "Scumbag",
    "Holographic",
    "Cyborg",
    "Laser Eyes",
    "Blue Beams",
  ],
  Closed_Eyes: ["Closed", "Blindfold", "Coins"],
  Sleepy_Eyes: ["Sleepy"],
  Angry_Eyes: ["Angry"],
  Sad_Eyes: ["Sad"],
  Wide_Eyed_Eyes: ["Wide Eyed", "Crazy", "Hypnotized"],
  X_Eyes_Eyes: ["X Eyes"],
};
export const allFaces = {
  Bored_Mouth: ["Bored", "Bored Unshaven"],
  Item_Mouth: [
    "Bored Cigarette",
    "Bored Unshaven Cigarette",
    "Bored Pipe",
    "Bored Unshaven Pipe",
    "Bored Cigar",
    "Bored Unshaven Cigar",
    "Bored Bubblegum",
    "Bored Unshaven Bubblegum",
    "Bored Party Horn",
    "Bored Unshaven Party horn",
    "Bored Kazoo",
    "Bored Unshaven Kazoo",
    "Bored Pizza",
    "Bored Unshaven Pizza",
    "Bored Dagger",
    "Bored Unshaven Dagger",
  ],
  Grin_Mouth: [
    "Grin",
    "Grin Multicolored",
    "Grin Gold Grill",
    "Grin Diamond Grill",
  ],
  Dumbfounded_Mouth: ["Dumbfounded"],
  Phoneme_Vuh_Mouth: ["Phoneme Vuh"],
  Jovial_Mouth: ["Jovial"],
  Small_Grin_Mouth: ["Small Grin"],
  Phoneme_ooo_Mouth: ["Phoneme ooo"],
  Phoneme_L_Mouth: ["Phoneme L"],
  Rage_Mouth: ["Rage"],
  Phoneme_Oh_Mouth: ["Phoneme Oh"],
  Discomfort_Mouth: ["Discomfort"],
  Tongue_Out_Mouth: ["Tongue Out"],
  Phoneme_Wah_Mouth: ["Phoneme Wah"],
};
export const cleanJsonValues = (singleObject) => {
  const cleanedObject = {};
  for (const key in singleObject) {
    if (singleObject.hasOwnProperty(key)) {
      const value = singleObject[key].replace(/\s+/g, " ").trim();
      cleanedObject[key] = value;
    }
  }
  return cleanedObject;
};

export function toTitleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt().toLowerCase())
    .join(" ");
}

export function GPUChecker(setPixelRatio) {
  const canvas = document.createElement("canvas");
  const gl =
    canvas.getContext("webgl") || canvas.getContext("experimental-webgl");

  if (gl) {
    const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
    const gpuRenderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
    if (
      gpuRenderer.toLowerCase().includes("intel") ||
      gpuRenderer.toLowerCase().includes("mali")
    ) {
      setPixelRatio(2);
    } else {
      setPixelRatio(3);
    }
  } else {
    setPixelRatio(2);
  }
}

export function GetTouchDevices() {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
}
