import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

export const TextInput = ({
  value = "",
  onChange = () => {},
  error = "",
  label = "",
  ...props
}) => {
  return (
    <TextField
      fullWidth
      label={label}
      variant="outlined"
      value={value}
      defaultValue={value}
      onChange={(e) => onChange(e.target.value)}
      error={!!error}
      helperText={error}
      inputProps={{
        style: {
          borderRadius: "8px",
          border: "1px solid #0D0D0D",
          backgroundColor: "#0D0D0D",
          color: "#fff",
          fontSize: "14px",
        },
      }}
      InputLabelProps={{
        style: {
          color: "#BEBEB5",
          fontSize: "16px",
          padding: "0 8px",
        },
      }}
      FormHelperTextProps={{ style: { color: "#f44336" } }}
      sx={{
        "& .MuiInputBase-multiline": {
          backgroundColor: "#0D0D0D",
        },
        // Remove outline completely
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#000", // Initial border color
          },
          "&:hover fieldset": {
            borderColor: "#000", // Border color on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "#0D0D0D", // Border color on focus (same as background)
          },
          // Override disabled input styles with higher specificity
          "&.Mui-disabled .MuiInputBase-input": {
            WebkitTextFillColor: "#BEBEB5 !important",
            color: "#BEBEB5 !important",
          },
        },
        // If you want to remove the box-shadow that appears on focus
        "& .MuiOutlinedInput-root.Mui-focused": {
          "& > fieldset": {
            border: "1px solid #000", // Maintains consistent border
          },
        },
      }}
      {...props}
    />
  );
};

export const RightEndedSubmitButton = () => (
  <Button
    type="submit"
    variant="contained"
    sx={{
      bgcolor: "#FFB800",
      color: "#000",
      fontWeight: "700",
      "&:hover": {
        bgcolor: "#cc9300",
      },
      width: "fit-content",
      alignSelf: "flex-end",
      textTransform: "none",
      px: 4,
      py: 1,
    }}
  >
    Submit
  </Button>
);

const radioGroupStyle = {
  "& .MuiFormLabel-root": {
    color: "#C4C4C4",
  },
  "& .MuiRadio-root": {
    color: "rgba(255, 255, 255, 0.23)",
    "&:hover": {
      backgroundColor: "rgba(255, 184, 0, 0.04)",
    },
    "&.Mui-checked": {
      color: "#FFB800",
    },
  },
  "& .MuiFormControlLabel-label": {
    color: "#BEBEB5",
  },
};

export const CustomRadioGroup = ({
  label = "",
  value = "",
  onChange = () => {},
  options = [],
  ...props
}) => {
  return (
    <FormControl sx={radioGroupStyle}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...props}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
