import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";

import ImagePageContainer from "../../components/common/ImagePageContainer";
import ProfileBody from "../../components/profile/ProfileBody";
import ProfileHeader from "../../components/profile/ProfileHeader";
import useProfileData from "../../hooks/useProfileData";

const PublicProfile = () => {
  const { address: urlAddress } = useParams();
  const { profileData, updateProfileData, isLoading } =
    useProfileData(urlAddress);

  const navigate = useNavigate();

  if (!isLoading && !profileData?.walletAddress) {
    navigate("/404");
  }

  return (
    <ImagePageContainer
      backgroundImage="https://storage.googleapis.com/3d-container/Pose_001%201.jpg"
      maxWidth="1260px"
    >
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          flexDirection: { xs: "column", lg: "row" },
          alignItems: { xs: "center", lg: "flex-start" },
        }}
      >
        <ProfileHeader
          profileData={profileData}
          updateProfileData={updateProfileData}
          isLoading={isLoading}
          editable={false}
        />
        <ProfileBody
          profileData={profileData}
          updateProfileData={updateProfileData}
          isLoading={isLoading}
          editable={false}
        />
      </Box>
    </ImagePageContainer>
  );
};

export default PublicProfile;
