import { useCallback, useEffect, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { TiPencil } from "react-icons/ti";
import { enqueueSnackbar } from "notistack";

import { TextInput } from "../ui/Inputs";
import { InactiveFont } from "../ui/Texts";

const EditProfile = ({
  profileData,
  updateProfileData,
  isLoading,
  editable,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});

  const resetForm = useCallback(() => {
    if (profileData) {
      setFormData({
        firstName: profileData?.firstName || "",
        lastName: profileData?.lastName || "",
        bio: profileData?.bio || "",
        xUsername: profileData?.xUsername || "",
        discord: profileData?.discord || "",
        email: profileData?.email || "",
        telegram: profileData?.telegram || "",
        profileImage: profileData?.profileImage || null,
      });
    }
  }, [profileData]);

  useEffect(() => {
    if (profileData) {
      resetForm();
    }
  }, [profileData, resetForm]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    const newErrors = {};

    // Validate email if provided
    if (formData.email && !validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formDataObj = new FormData();

    Object.keys(formData).forEach((key) => {
      if (key === "profileImage" && formData[key] instanceof File) {
        console.log(formData[key]);

        formDataObj.append(key, formData[key], formData[key].name); // Append file
      } else {
        formDataObj.append(key, formData[key]); // Append other fields
      }
    });

    try {
      await updateProfileData(formDataObj);
      setIsEditing(false);
      enqueueSnackbar("Profile Updated!", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      const errorMessage =
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        "Failed to submit form. Please try again.";

      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Clear error when user starts typing
    if (errors[field]) {
      setErrors((prev) => ({
        ...prev,
        [field]: "",
      }));
    }
  };

  const SaveButton = () =>
    isLoading ? (
      <CircularProgress size="30px" />
    ) : (
      <Button
        variant="contained"
        disabled={isLoading}
        onClick={handleSubmit}
        size="small"
        sx={{
          textTransform: "none",
          border: "1px solid ",
          "&:hover": {
            backgroundColor: "#FFB800",
            border: "1px solid #FFB800",
          },
        }}
      >
        Save
      </Button>
    );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        {editable && !isEditing && (
          <Button
            variant="text"
            endIcon={<TiPencil />}
            onClick={() => setIsEditing(true)}
            disabled={isEditing}
          >
            Edit
          </Button>
        )}
        {isEditing && <SaveButton />}
      </Box>

      <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
        <TextInput
          fullWidth
          label="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={(value) => handleChange("firstName", value)}
          disabled={!isEditing}
        />
        <TextInput
          fullWidth
          label="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={(value) => handleChange("lastName", value)}
          disabled={!isEditing}
        />
        <TextInput
          label="Bio"
          value={formData.bio}
          name="bio"
          onChange={(value) => handleChange("bio", value)}
          error={errors.bio}
          multiline
          rows={4}
          maxLength={200}
          disabled={!isEditing}
        />
        <TextInput
          label="Email"
          name="email"
          value={formData.email}
          onChange={(value) => handleChange("email", value)}
          error={errors.email}
          maxLength={30}
          disabled={!isEditing}
        />
        <InactiveFont text="Social connections" style={{ color: "#fff" }} />
        <TextInput
          fullWidth
          label="X / Twitter"
          name="xUsername"
          value={formData.xUsername}
          onChange={(value) => handleChange("xUsername", value)}
          disabled={!isEditing}
        />
        <TextInput
          fullWidth
          label="Telegram"
          name="telegram"
          value={formData.telegram}
          onChange={(value) => handleChange("telegram", value)}
          disabled={!isEditing}
        />
        <TextInput
          fullWidth
          label="Discord"
          name="discord"
          value={formData.discord}
          onChange={(value) => handleChange("discord", value)}
          disabled={!isEditing}
        />
      </Box>

      {isEditing && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
          <Button
            variant="outlined"
            onClick={() => {
              resetForm();
              setIsEditing(false);
            }}
            size="small"
          >
            Cancel
          </Button>
          <SaveButton />
        </Box>
      )}
    </Box>
  );
};

export default EditProfile;
