import React, { useState, useEffect } from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { BackButton, NftContactLinks } from "../../components";
import HeroSlider from "./HeroSlider";
import NftSearchField from "./NftSearchField";

const DesktopNftDetails = ({
  projectDetail,
  collecttionData,
  nfts,
  pathname,
  getCompanyName,
  palette,
  theme,
  navigate,
}) => {
  const isSmall = useMediaQuery("(max-width:900px)");
  const isMobile = useMediaQuery("(max-width:599px)");

  // Search state
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredNfts, setFilteredNfts] = useState(nfts || []);

  // Update filtered NFTs when search query changes or when nfts data changes
  useEffect(() => {
    if (!nfts) return;

    if (searchQuery.trim() === "") {
      setFilteredNfts(nfts);
    } else {
      const filtered = nfts.filter((nft) =>
        nft.nftId.toString().includes(searchQuery.trim())
      );
      setFilteredNfts(filtered);
    }
  }, [searchQuery, nfts]);

  return (
    <Box
      component="section"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          justifyContent: "center",
        },
      }}
    >
      <img
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          left: 0,
          backgroundSize: "contain",
          objectFit: "cover",
          zIndex: -2,
        }}
        alt={projectDetail?.name}
        src={projectDetail?.sliderImg}
        loading="lazy"
      />

      <Box
        sx={{
          paddingTop: "10%",
          paddingLeft: "60px",
          width: "10%",
        }}
      >
        <BackButton
          customBack={true}
          onClick={() => {
            if (projectDetail?.backURL) {
              navigate("/" + projectDetail?.backURL);
            } else {
              navigate("/official-demo");
            }
          }}
        />
      </Box>
      <Box
        className=""
        sx={{
          overflowY: "hidden",
          borderRadius: "22px",
          backdropFilter: "blur(25px)",
          height: "100%",
          width: "45%",
          marginTop: "8vh",
          padding: "8px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderRadius: "0.5vw",
            border: "1px solid #4A4A4A",
            zIndex: 1000,
            padding: "10px 20px",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "40%",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  textTransform: "capitalize",
                  fontFamily: "var(--font-f_r)",
                  fontSize: "1.8vw",
                  color: palette.grey[200],
                  [theme.breakpoints.down("md")]: {
                    fontSize: "28px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "22px",
                  },
                }}
              >
                {collecttionData?.name}
              </Typography>
              <Button
                sx={{
                  color: palette.button.default,
                  backgroundColor: "transparent",
                  border: "2px solid #FFFFFFD4",
                  height: "3em",
                  fontWeight: "700",
                  fontSize: "0.6vw",
                  borderRadius: "0.4vw",
                  textTransform: "uppercase",
                  [theme.breakpoints.down("md")]: {
                    height: "3em",
                    fontSize: "8px",
                    width: "12em",
                  },
                  [theme.breakpoints.down("sm")]: {
                    height: "3em",
                    fontSize: "8px",
                    width: "12em",
                  },
                  "&:hover": {
                    borderColor: "#FFFFFFD4",
                  },
                }}
                variant="outlined"
              >
                by {getCompanyName(pathname?.split("/")[1])}
              </Button>
            </Box>
            <Box
              sx={{
                width: "50%",
              }}
              display="flex"
              justifyContent="space-between"
            >
              <NftContactLinks
                connections={collecttionData.connections}
                topName="LINKS"
                componentKey="desktop"
              />
              <Box sx={{ backgroundColor: "#fff" }} height="80%" width="2px" />
              <Box width={"40%"} marginLeft={"20px"}>
                <NftContactLinks
                  connections={collecttionData.buyNowConnections}
                  topName="BUY NOW"
                  componentKey="mobilex"
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexWrap="wrap" marginTop={1}>
            {collecttionData.prices.map((price) => {
              return (
                <Box
                  key={price.id + "s"}
                  sx={{
                    border: "1px solid #5c5c5c",
                    borderRadius: "0.5vw",
                    whiteSpace: "nowrap",
                    alignItems: "center",
                    padding: "8px",
                    margin: "0px 16px 16px 0px",
                    width: {
                      xs: "100px",
                      sm: "120px",
                      md: "12.5%",
                      lg: "13.5%",
                      xl: "160px",
                    },
                    height: "auto",
                    fontFamily: "var(--font-i_r)",
                    marginLeft: "0px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: {
                        xl: "1.2em",
                        lg: "0.8em",
                        md: "0.6em",
                        xs: "0.7em",
                        sm: "0.7em",
                      },
                      textAlign: "center",
                      color: "#fff",
                      fontFamily: "var(--font-i_r)",
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "12px",
                      },
                    }}
                  >
                    {price.value}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontFamily: "var(--font-i_r)",
                      fontSize: {
                        xl: "0.9em",
                        lg: "0.6em",
                        md: "0.5em",
                        xs: "0.7em",
                        sm: "0.7em",
                      },
                      textAlign: "center",
                      textTransform: "capitalize",
                      color: palette.font.default,
                    }}
                  >
                    {price.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="left"
          flexDirection="column"
          width="100%"
        >
          <Typography
            sx={{
              paddingLeft: "20px",
              marginBlock: "40px",
              fontWeight: "500",
              fontFamily: "var(--font-i_r)",
              fontSize: {
                xl: "1.2em",
                lg: "12px",
                md: "0.6em",
                xs: "0.7em",
                sm: "0.7em",
              },
              lineHeight: { xl: "28px", lg: "auto" },
              letterSpacing: { xl: "0.7px", lg: "0.4px" },
              textAlign: "left",
              textTransform: "capitalize",
              color: palette.grey[200],
              margin: "2px 8px 2px 0px",
              padding: "16px 8px 2px 8px",
            }}
          >
            {isMobile
              ? collecttionData?.description?.length > 100
                ? collecttionData.description?.slice(0, 100) + "..."
                : collecttionData.description
              : collecttionData.description}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            alignItems: "center",
            marginTop: { xl: "16px", lg: "13px", md: "5px" },
            padding: "0 8px 0 20px",
            [theme.breakpoints.down("sm")]: {
              justifyContent: "space-between",
            },
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontFamily: "var(--font-i_r)",
              fontSize: {
                xl: "1.2em",
                lg: "0.9em",
                md: "0.4em",
                xs: "0.7em",
                sm: "0.7em",
              },
              lineHeight: { xl: "20px", lg: "16px" },
              letterSpacing: { xl: "0.2px", lg: "0.2px" },
              color: palette.grey[200],
              marginBlock: "10px",
              margin: "2px 8px 2px 0px",
            }}
          >
            Choose your asset
          </Typography>
          <NftSearchField
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </Box>

        <Box>
          {filteredNfts && filteredNfts.length > 0 ? (
            <HeroSlider projects={filteredNfts} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: 3,
                marginTop: 2,
                color: "#fff",
                borderRadius: "10px",
                backgroundColor: "rgba(0,0,0,0.4)",
                border: "1px solid rgba(255,184,0,0.3)",
              }}
            >
              <Typography align="center">
                No NFTs found with ID: {searchQuery}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DesktopNftDetails;
