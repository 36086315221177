import axios from "../api";

export const validationService = {
  async Companies(pageSize = 7) {
    try {
      const response = await axios.get(
        `dashboard/companies?pageSize=${pageSize}&page=1`
      );
      const isValid = response.status === 200
      return {
        isValid,
        data: isValid ? response?.data?.companies : null,
        error: !isValid ? response.data.message : null
      };
    } catch (error) {
      return {
        isValid: false,
        data: null,
        error: 'Failed to validate client'
      };
    }
  },

  CompanyProjects: async (slug) => {
    try {
      // Store the controller to handle cleanup
      const controller = new AbortController();

      const response = await axios.get(`companies/slug/${slug}`, {
        signal: controller.signal
      });

      return {
        isValid: true,
        data: response.data,
        error: null,
        controller // Return controller for cleanup
      };
    } catch (error) {
      // Handle different types of errors

      return {
        isValid: false,
        data: null,
        error: error.response?.data?.message || 'Failed to validate client'
      };
    }
  },
  async clientProjectsDetails(project, collection) {
    try {
      const response = await axios.get(`validate/collection/slogan/${project}/${collection}`);
      const isValid = response.status === 200
      return {
        isValid,
        data: isValid ? response?.data : null,
        error: !isValid ? response.data.message : null
      };
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data?.message || 'Server error occurred',
        data: error.response.data
      };


    }
  },
  async getNftById(slogan, id) {
    try {
      const response = await axios.get(`opensea/token/${id}/${slogan}`);
      const isValid = response.status === 200

      return {
        isValid,
        data: isValid ? response?.data : null,
        error: !isValid ? response.data.message : null
      };
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data?.message || 'Server error occurred',
        data: error.response.data
      };
    }
  },
  async getModelData(id, collection) {
    try {
      const response = await axios.get(`nfts/${id}/${collection}`);
      const isValid = response.status === 200

      return {
        isValid,
        data: isValid ? response?.data : null,
        error: !isValid ? response.data.message : null
      };
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data?.message || 'Server error occurred',
        data: error.response.data
      };
    }
  }
};